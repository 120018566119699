<template>
    <div class="t-data-row" :class="{'mini': mini}">
        <div class="t-data-title">
            <i class="far mr-2" :class="icon" v-if="icon"></i><strong>{{title}}</strong>
        </div>
        <div class="t-data-value">
            <slot/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DataRow",
        props: {
            title: String,
            icon: String,
            mini: Boolean
        }
    };
</script>

<style lang="scss" scoped>
    .t-data-row {
        font-size: 0.85rem;

        border-bottom: 1px solid #efefef;
        /*padding: 0 1.2rem;*/

        display: flex;
        flex-wrap: wrap;

        .t-data-title {
            color: darken($t-primary, 18) !important;

            border-right: 1px solid #efefef;
            flex: 0 0 25%;
            min-width: 25%;
            padding: 0.4rem 0.3rem 0.3rem 1.3rem;
        }

        .t-data-value {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
            padding: 0.4rem 0.3rem 0.3rem 0.8rem;
        }


        &.mini {
            font-size: 0.7rem;
            padding: 0;
            margin: 0;

            .t-data-title, .t-data-value {
                padding: 0.2rem 0.4rem;
            }
        }

        &:last-child {
            border: 0;
        }
    }
</style>
