<template>
    <ul class="t-card-list" :class="{'border-top': borderTop, 'border-bottom': borderBottom}">
        <slot></slot>
    </ul>
</template>

<script>
    export default {
        name: 'CardList',
        props: {
            borderTop: Boolean,
            borderBottom: Boolean
        }
    }
</script>

<style lang="scss">
    ul.t-card-list {
        list-style: none;
        padding: 0;
        margin: 0;

        &.border-top {
            border-top: 1px solid #e7e7e7;
        }

        &.border-bottom {
            border-bottom: 1px solid #e7e7e7;
        }

        >li, .t-card-list-item {
            background-color: #fafafa;
            border-bottom: 1px solid #e7e7e7;
            cursor: pointer;
            border-left: 0.4rem solid transparent;

            &:hover {
                background-color: white;
            }

            &.active {
                border-left: 0.4rem solid $t-primary;
                background-color: white;
                color: $t-primary;
            }
        }
    }
</style>
