<template>
    <li @click="$emit('click')" :class="{'no-hover': noHover}">
        <div class="t-caret" @click.stop="details=!details; $emit('details', details)"><i class="fas" :class="{'fa-caret-right': ($slots.details && !details), 'fa-caret-down': ($slots.details && details)}"></i></div>
        <div class="t-icon" @click.stop="details=!details; $emit('details', details)">
            <slot name="icon"/>
        </div>
        <div class="t-li-content">
            <div class="main-content-container">
                <div class="main-content">
                    <slot name="content"/>
                </div>
                <div class="status" v-if="$slots.status">
                    <slot name="status"/>
                </div>
            </div>
            <div class="sub-content" v-if="$slots.subcontent">
                <slot name="subcontent"/>
            </div>
            <div class="details" v-show="details">
                <slot name="details"/>
            </div>
        </div>
        <div class="indicator">
            <slot name="badge"/>
        </div>
        <div class="chevron" v-if="chevron"><i class="fas fa-chevron-right"></i></div>
    </li>
</template>

<script>
    export default {
        name: "CardListItem",
        props: {
            chevron: {
                type: Boolean
            },
            noHover: {
                type: Boolean
            }
        },
        data: function () {
            return {
                details: false
            };
        }
    };
</script>

<style lang="scss" scoped>
    li {
        display: flex;
        position: relative;
        padding: 0.55rem 0;

        &.no-hover:hover {
            background-color: #fafafa !important;
            cursor: default !important;
        }
    }

    .t-caret {
        margin-left: 5px;
        min-width: 12px;
        margin-top: 1px;
    }

    .t-icon {
        /*background-color: lighten(red, 40);*/
        min-width: 18px;
        text-align: center;
        margin-right: 10px;

        i {
            font-size: 0.9rem;
        }
    }

    .t-li-content {
        flex-grow: 1;
        /*background-color: lighten(blue, 45);*/

        .meta {
            font-size: 0.75rem;
            color: #5e5e5e;
        }

        .main-content-container {
            display: flex;

            .main-content {
                padding-top: 1px;
                flex-grow: 1;
            }

            .status {
                white-space: nowrap;
                margin-left: 1rem;
            }
        }

        .status {
            .stats {
                margin-top: 0.1rem;
                font-size: 0.7rem;

                .stat {
                    margin-left: 0.5rem;

                    i {
                        margin-right: 0.2rem;
                        font-size: 0.65rem;
                    }
                }
            }
        }
    }

    .details {
        cursor: default !important;

        .data {
            background-color: white;
            border-radius: 0.3rem;
            border: 1px solid #efefef;
        }

        .toolbar {
            background-color: white;
            border-radius: 0.3rem;
            border: 1px solid #efefef;
            padding: 0.2rem 0.3rem;
        }
    }

    .indicator {
        text-align: right;
        margin-right: 15px;

        .badge {
            padding: 3px 7px 4px;
            font-size: 0.70rem;
        }
    }

    .chevron {
        min-width: 26px;
    }
</style>
