<template>
    <div>
        <card-header title="Archived Trips" icon="fa-plane-arrival"/>

        <card-body>
            <card-list>
                <trip-list-item
                        :trip="trip"
                        :card="card"
                        v-for="trip in trips"
                        :active="trip.active"
                        @click="$openCard('trip', {tripId: trip.id}, card)"
                />
            </card-list>
            <infinite-loading @infinite="loadTrips">
                <div slot="no-more"></div>
                <div slot="no-results">No Records Found</div>
            </infinite-loading>
        </card-body>

        <card-footer>
            Showing <strong>{{trips.length}}</strong> of <strong>{{archiveCount}}</strong> total archived.
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import TripListItem from "../../components/TripListItem";
    import CardBody from "../../TIER/components/CardBody";

    import {client as http} from '../../http_client';
    import CardFooter from "../../TIER/components/CardFooter";

    export default {
        name: "Users",
        props: ['card', 'child'],
        components: {CardFooter, CardBody, TripListItem, CardList, CardHeader},
        data: function () {
            return {
                trips: [],
                archiveCount: 0,
                paging: {
                    start: 0,
                    count: 30
                }
            };
        },
        methods: {
            loadTrips: function ($state) {
                console.log('loadTrips');
                http.get(`/api/trips/archived/${this.paging.start}/${this.paging.count}`).then(response => {
                    for (let i = 0; i < response.data.trips.length; i++) {
                        response.data.trips[i].active = false;
                    }
                    this.trips.push(...response.data.trips);
                    this.archiveCount = response.data.count;
                    this.paging.start += response.data.trips.length;

                    if ($state) {
                        if (response.data.count === this.trips.length) {
                            console.log('complete!');
                            $state.complete();
                        } else {
                            $state.loaded();
                        }
                    }

                    this.setActive();
                    this.$emit('loading', false);
                });
            },
            setActive: function () {
                let check = (this.child && this.child.definition.component === 'trip') ? parseInt(this.child.props.tripId) : null;
                this.trips.map(t => t.active = (t.id === check));
            }
        },
        watch: {
            'child': function () {
                this.setActive();
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
