<template>
    <div class="mh-card-body" :class="{'padded': padded}">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "CardBody",
        props: {
            noHeader: Boolean,
            footer: Boolean,
            toolbar: Boolean,
            subheader: Boolean,
            padded: Boolean
        }
    }
</script>

<style lang="scss" scoped>

    .mh-card-body {
        flex-grow: 1;

        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        overflow-y: auto;
        overflow-x: hidden;

        &.padded {
            padding: 1rem 1.5rem;
        }
    }
</style>
