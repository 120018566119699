<template>
    <div class="mh-card-toolbar">
        <div class="left"><slot/></div>
        <div class="right"><slot name="right"/></div>
        <div class="clear"></div>
    </div>
</template>

<script>
    export default {
        name: "Toolbar"
    }
</script>

<style lang="scss">
    .mh-card-toolbar {
        .left {
            float: left;
        }

        .right {
            float: right;
            text-align: right;
        }

        .clear::after {
            content: "";
            clear: both;
            display: table;
        }

        button {
            padding: 0.2rem 0.4rem;
            line-height: normal;
            font-size: 0.82rem;

            background-color: transparent;
            border: 0;
            color: $t-primary;
            border-radius: 0.2rem;
            margin-right: 0.2rem;

            cursor: pointer;

            &.icon-only i {
                margin-right: 0 !important;
            }

            i {
                margin-right: 0.4rem;
            }

            &:hover {
                background: $t-primary;
                color: white;
            }
        }
    }
</style>
