<template>
    <div class="mh-card-footer">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "CardFooter"
    }
</script>

<style lang="scss" scoped>
    .mh-card-footer {
        font-size: 0.7rem;

        padding: 4px 20px;
        margin: 0;
        background: #f3f3f3;
        border-top: 1px solid #dee2e6;
        border-radius: 0 0 5px 5px;
    }
</style>
