<template>
    <div class="t-card-header" :class="{'subheader': subheader}">
        <h1><i class="fas fa-fw" :class="icon"></i><slot name="title">{{title}}</slot></h1>
        <h2 v-if="subheader">{{subheader}}</h2>
    </div>
</template>

<script>
    export default {
        name: "CardHeader",
        props: ['title', 'icon', 'subheader']
    }
</script>

<style lang="scss" scoped>
    .t-card-header {
        padding: 15px 20px 10px;
        margin: 0;
        background: #e9e9e9;
        border-bottom: 1px solid #dee2e6;
        border-radius: 5px 5px 0 0;

        h1 {
            font-family: 'Montserrat', sans-serif;
            color: darken($t-primary, 10);
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: bold;
            letter-spacing: 0.5px;
            padding: 0;
            margin: 0;
        }

        h2 {
            color: darken($t-primary, 10);
            font-size: 0.75rem;
            font-weight: normal;
            padding: 6px 0 2px;
            margin: 0;
        }

        i {
            font-size: 16px;
            margin-right: 0.6rem;
            color: $t-primary;
        }

        &.subheader {
            padding-bottom: 5px;
        }
    }

</style>
